<template>
  <div class="">
    <div class="top"></div>
    <div class="card">
      <div class="item">
        <img
          src="https://dximg.dexian.ren/people.png"
          style="width: 18px; vertical-align: middle; margin-right: 11.5px"
        /><span class="left">联系人：</span
        ><span class="left" style="float: right; color: #262626">{{
          list.Charger
        }}</span>
      </div>
      <div class="item">
        <img
          src="https://dximg.dexian.ren/phone.png"
          style="width: 18px; vertical-align: middle; margin-right: 11.5px"
        /><span class="left">手机号：</span
        ><span class="left" style="float: right; color: #262626">{{
          list.TouchTel
        }}</span>
      </div>
      <div class="item">
        <img
          src="https://dximg.dexian.ren/adresdetails.png"
          style="width: 18px; vertical-align: middle; margin-right: 11.5px"
        /><span class="left">联系地址：</span
        ><span class="left" style="float: right; color: #262626">{{
          list.Address
        }}</span>
      </div>
    </div>
    <div class="content">
      <span v-html="list.Introduce"></span>
    </div>
  </div>
</template>

<script>
import {
  WeMyMemberInfo,
  WxGetNeInfo,
  WeGetRealInfoPage,
  WePBGetRealInfoPage,
  WeGetMyActivityPage,
} from "@/api/RealInfo";
export default {
  // 组件名称
  name: "",
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  components: {},
  // 组件状态值
  data() {
    return {
      infoId: this.$route.params.id,
      list: {},
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    // 获取邻里中心 详情
    async getNeInfo() {
      const res = await WxGetNeInfo({ infoId: this.infoId });
      console.log(res, "邻里中心 详情");
      this.list = res.data.data;
    },
  },
  created() {
    this.getNeInfo();
  },
  mounted() {},
};
</script> 

<style scoped >
.top {
  width: 100%;
  height: 120px;
  /* background: linear-gradient(123deg, #ff544f, #ff8783); */
  background: #ff5d58;
  border-radius: 0px 0px 0px 30px;
  position: absolute;
}

.card {
  height: 115px;
  background: #ffffff;
  box-shadow: 0px 4px 7px 0px rgba(80, 80, 80, 0.08);
  border-radius: 8px;
  width: 95%;
  margin: auto;
  position: relative;
  margin-top: 60px;
  box-sizing: border-box;
  padding: 17px 18px 23px 15px;
}

.left {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 100;
  color: #666666;
}

.item {
  line-height: 27px;
}

.content {
  box-sizing: border-box;
  padding: 29px 18px;
  font-size: 14px;
  font-family: PingFang SC;
  line-height: 25px;
  color: #0f0f0f;
}

.content img {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  border-radius: 8px;
}
</style>